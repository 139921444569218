import { useState, useEffect } from "react";
import dayjs from "dayjs";

export const useAuth = () => {
  const [loginState, setLoginState] = useState(() => {
    const storedState = sessionStorage.getItem("loginState");
    return storedState
      ? JSON.parse(storedState)
      : {
          isLogin: false,
          showLogin: false,
          lastLoginTime: "0000-00-00 00:00:00",
          loggedInMemberName: "",
          loggedInMemberId: "",
          loggedInMemberLevel: "",
          token: null,
        };
  });

  const [lastLogoutTime, setLastLogoutTime] = useState(() => {
    return localStorage.getItem("lastLogoutTime") || "0000-00-00 00:00:00";
  });

  useEffect(() => {
    sessionStorage.setItem("loginState", JSON.stringify(loginState));
  }, [loginState]);

  useEffect(() => {
    localStorage.setItem("lastLogoutTime", lastLogoutTime);
  }, [lastLogoutTime]);

  const login = (userData, token) => {
    const currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    setLoginState({
      isLogin: true,
      showLogin: false,
      lastLoginTime: currentTime,
      loggedInMemberName: userData.mb_name,
      loggedInMemberId: userData.mb_id,
      loggedInMemberLevel: userData.mb_level,
      token: token,
    });
    sessionStorage.setItem("token", token);
  };

  const logout = () => {
    const currentTime = dayjs().format("YYYY-MM-DD HH:mm:ss");
    setLastLogoutTime(currentTime);
    localStorage.setItem("lastLogoutTime", currentTime); // Immediately update localStorage
    setLoginState({
      isLogin: false,
      showLogin: false,
      lastLoginTime: loginState.lastLoginTime,
      loggedInMemberName: "",
      loggedInMemberId: "",
      loggedInMemberLevel: "",
      token: null,
    });
    sessionStorage.removeItem("token");
  };

  const toggleLoginPopup = (show) => {
    setLoginState((prev) => ({ ...prev, showLogin: show }));
  };

  return { loginState, lastLogoutTime, login, logout, toggleLoginPopup };
};
