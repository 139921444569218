import React, { useState } from "react";
import {
  TextField,
  Button,
  Box,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { styled } from "@mui/system";
import axios from "axios";
import SPRING_API_URL from "../utils/SpringApiUrl";

const SearchContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),
  [theme.breakpoints.down("sm")]: {
    flexDirection: "column",
  },
}));

const ProductList = () => {
  const [searchDates, setSearchDates] = useState({
    from: new Date().toISOString().split("T")[0],
    to: new Date().toISOString().split("T")[0],
  });
  const [products, setProducts] = useState([]);

  const handleDateChange = (e) => {
    const { name, value } = e.target;
    setSearchDates((prev) => ({ ...prev, [name]: value }));
  };

  const handleSearch = async () => {
    const config = {
      method: "POST",
      url: `${SPRING_API_URL}/register/product-list`,
      data: {
        from: searchDates.from,
        to: searchDates.to,
      },
    };
    try {
      const response = await axios.request(config);
      setProducts(response.data);
    } catch (error) {
      console.error("Error fetching product list:", error);
    }
  };

  return (
    <Box>
      <Typography variant="h6" gutterBottom>
        Product List
      </Typography>
      <SearchContainer>
        <TextField
          label="From Date"
          name="from"
          type="date"
          value={searchDates.from}
          onChange={handleDateChange}
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="To Date"
          name="to"
          type="date"
          value={searchDates.to}
          onChange={handleDateChange}
          fullWidth
          variant="outlined"
          InputLabelProps={{ shrink: true }}
        />
        <Button variant="contained" onClick={handleSearch}>
          Search
        </Button>
      </SearchContainer>
      <TableContainer component={Paper} elevation={0}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Barcode</TableCell>
              <TableCell>Name (KR)</TableCell>
              <TableCell>Name (EN)</TableCell>
              <TableCell>Category</TableCell>
              <TableCell>Created At</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {products.map((product) => (
              <TableRow key={product.productInfoId} hover>
                <TableCell>{product.productInfoId}</TableCell>
                <TableCell>{product.itBarcode || "N/A"}</TableCell>
                <TableCell>{product.itName || "N/A"}</TableCell>
                <TableCell>{product.enItName || "N/A"}</TableCell>
                <TableCell>
                  {`${product.caName || "N/A"} > ${
                    product.caName2 || "N/A"
                  } > ${product.caName3 || "N/A"} > ${
                    product.caName4 || "N/A"
                  }`}
                </TableCell>
                <TableCell>{product.createdAt}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
};

export default ProductList;
