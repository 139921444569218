import React, { useState } from "react";
import { Box, Tabs, Tab, Paper } from "@mui/material";
import { styled } from "@mui/material/styles"; // 이 부분을 수정했습니다
import ProductRegistrationForm from "./ProductRegistrationForm";
import ProductList from "./ProductList";

const PageContainer = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  minHeight: "100vh",
  padding: theme.spacing(3),
}));

const ContentContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[3],
}));

const ProductManagement = () => {
  const [tabValue, setTabValue] = useState(0);

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <PageContainer>
      <ContentContainer>
        <Box sx={{ borderBottom: 1, borderColor: "divider", mb: 2 }}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            aria-label="product management tabs"
          >
            <Tab label="Register Product" />
            <Tab label="Product List" />
          </Tabs>
        </Box>
        <TabPanel value={tabValue} index={0}>
          <ProductRegistrationForm />
        </TabPanel>
        <TabPanel value={tabValue} index={1}>
          <ProductList />
        </TabPanel>
      </ContentContainer>
    </PageContainer>
  );
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </div>
  );
}

export default ProductManagement;
